import { useLocation, useParams } from '@remix-run/react'

import { urlToCleanPath } from '~/constants/paths'

export default function useCurrentLocation() {
  const params = useParams()
  const location = useLocation()

  let url = location?.pathname ?? ''
  const search = location?.search
  if (location?.search) url += `${search}`

  const cleanUrl = urlToCleanPath(params, url)
    .replace(/retries=\d*/, '') // Remove retries search param from url.
    .replace(/\?$/, '') // Clear trailling question mark if retries is the only param

  return [url, cleanUrl, new URLSearchParams(search)] as const
}
